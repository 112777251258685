import React, { useEffect, useState } from 'react';
import { Button } from '@missionlabs/smartagent-app-components';
import './call-overview.scss';
import { answerCall, skipCall as skip, endCall, clearContact } from '../../messaging';
import { CurrentCallInformation as CurrentCallState } from '../../types';

interface Props {
    currentCall?: CurrentCallState;
    statusError?: boolean
}

const CallOverview: React.FC<Props> = ({ currentCall, statusError }: Props) => {
    const [seconds, setSeconds] = useState<number>(0);
    const [errorAnswering, setErrorAnswering] = useState<boolean>(false);
    const [errorClear, setErrorClear] = useState<boolean>(false);
    const [errorSkipping, setErrorSkipping] = useState<boolean>(false);

    useEffect(() => {
        setSeconds(0);
        const interval = setInterval(() => {
            setSeconds((prevSeconds) => prevSeconds + 1);
        }, 1000);

        return () => {
            clearInterval(interval);
        };
    }, [currentCall?.callStatus]);

    const getStatus = () => {
        let state, background;
        switch (currentCall?.callStatus) {
            case 'CustAnswered':
                state = 'Ongoing';
                background = 'green';
                break;
            case 'ACW':
                state = 'Wrap up';
                background = 'orange';
                break;
            case 'initiate':
                state = 'Initiating';
                background = 'blue';
                break;
            default:
                state = 'Preview';
                background = 'grey';
                break;
        }
        return <div className={`call-overview-wrapper__status ${background}`}>{state}</div>;
    };

    const skipCall = () => {
        try {
            skip();
            // need to update to check if SKIP_CONTACT_FAILED is returned from iframe
        } catch (err) {
            console.log('An error occurred skipping the contact', err);
            // setErrorSkipping(true);
        }
    };

    const acceptCall = () => {
        try {
            answerCall();
            // need to update to check if ANSWER_CALL_FAILED is returned from iframe
        } catch (err) {
            console.log('An error occurred answering the call', err);
            setErrorAnswering(true);
        }
    };

    const renderCallButtons = () => {
        if (currentCall?.callStatus === 'CustAnswered') {
            return (
                <Button className="button" styling="red" onClick={() => endCall()}>
                    End Call
                </Button>
            );
        } else if (currentCall?.callStatus === 'ACW') {
            return (
                <Button
                    className="button"
                    styling="red"
                    onClick={() => {
                        clearContact();
                    }}
                >
                    Clear Call
                </Button>
            );
        } else if (currentCall?.callStatus === 'Preview') {
            return (
                <>
                    <Button className="button" styling="simple" onClick={() => skipCall()}>
                        Skip
                    </Button>
                    <Button className="button" styling="primary" onClick={() => acceptCall()}>
                        Accept
                    </Button>
                </>
            );
        }
    };

    const secondsTimer = (seconds % 60).toString().padStart(2, '0');
    const minutesTimer = Math.floor(seconds / 60)
        .toString()
        .padStart(2, '0');

    return (
        <div className="call-overview-wrapper">
            {!currentCall?.callStatus && currentCall?.pacingMode !== 'PREVIEW' ? (
                <div className="call-overview-wrapper__title">Ready for Call</div>
            ) : (
                <>
                    <div className="call-overview-wrapper__title">Customer Call</div>
                    <div className="call-overview-wrapper__main">
                        <div className="call-overview-wrapper__main__info">
                            {currentCall.contactNumber && (
                                <div className="phone-number">{currentCall.contactNumber}</div>
                            )}
                            {getStatus()}
                        </div>
                        <div className="call-overview-wrapper__main__buttons">
                            {renderCallButtons()}
                        </div>
                    </div>
                </>
            )}
            <div className="call-overview-wrapper__timer">{`${minutesTimer}:${secondsTimer}`}</div>
            {errorAnswering && (
                <div className="error">An error occurred when trying to answer the call</div>
            )}
            {errorClear && (
                <div className="error">An error occurred when disconnecting the call</div>
            )}
            {errorSkipping && (
                <div className="error">An error occurred when skipping the contact</div>
            )}
            {statusError && (
                <div className="error">An error occurred when changing status</div>
            )}
        </div>
    );
};

export default CallOverview;
