const isSandbox = process.env.REACT_APP_IS_SANDBOX === 'true';
const env = isSandbox ? 'sandbox' : process.env.REACT_APP_ENV || 'sandbox';

const urls: { [key: string]: string } = {
    sandbox: 'https://lhksmnfdq6.execute-api.eu-west-2.amazonaws.com/dev',
    dev: 'https://1fgjhkkc52.execute-api.eu-west-2.amazonaws.com/dev',
    uat: '',
    prod: ''
};

export const BASE_API_URL = urls[env] || urls.dev;

const acqueonIframeUrls: {[key: string]: string} = {
    sandbox: 'https://twaddev.aecdevops.com/desktop/view/Amazon_DEV_ML',
    dev: 'https://twadsit.aecdevops.com/desktop/view/Amazon_SIT_ML',
    uat: '',
    prod: ''
}

export const ACQUEON_IFRAME_URL = acqueonIframeUrls[env]
