import { nanoid } from 'nanoid';

interface SendMessageParams {
    channel: string;
    topic?: string;
    subTopic?: string;
    message?: MessageParams | string;
}

interface MessageParams {
    channel: string;
    topic: string;
    subtopic: string;
    source: string;
    data?: any;
}

export interface BusinessOutcomeRequest {
    callid: string;
    AccountNumber: string;
    OutcomeId: number;
    LeadScore: number;
    UserId: string;
    AgentComments: string;
    TargetAmount: number;
}

const originSplit = window.location.href.split('/');
const plainOrigin = `${originSplit[0]}//${originSplit[2]}`;

const getSubscriberID = () => {
    const key = 'subscriberID';
    let ID = localStorage.getItem(key);

    if (!ID) {
        ID = nanoid();
        localStorage.setItem(key, ID);
    }

    return ID;
};

export const sendMsg = (params: SendMessageParams) => {
    const iframeWindow = (document.getElementById('acqueon-iframe') as HTMLIFrameElement | null)
        ?.contentWindow;
    if (!iframeWindow) {
        console.error('sendMsg:: could not find acqueon iframe');
        return;
    }

    iframeWindow.postMessage(
        {
            channel: params.channel,
            topic: params.topic,
            subTopic: params.subTopic,
            message: params.message,
            ResponseUrl: plainOrigin,
            subscriberID: getSubscriberID()
        },
        '*'
    );
};

export const register = () => {
    sendMsg({
        channel: 'AcqueonDesktop',
        topic: '*',
        subTopic: '*',
        message: 'register'
    });
};

export const lcmRegister = () => {
    sendMsg({
        channel: 'LCM',
        topic: '*',
        subTopic: '*',
        message: 'register'
    });
};

export const stateSync = () => {
    sendMsg({
        channel: 'LCM',
        message: {
            subtopic: 'StateSync',
            channel: 'LCM',
            topic: 'WebService',
            source: 'SmartAgent'
        }
    });
};

export const answerCall = () => {
    sendMsg({
        channel: 'Amazon',
        message: {
            channel: 'Amazon',
            topic: 'OutboundCall',
            subtopic: 'MakeCall',
            source: 'SmartAgent'
        }
    });
};

export const endCall = () => {
    sendMsg({
        channel: 'Amazon',
        message: {
            channel: 'Amazon',
            topic: 'OutboundCall',
            subtopic: 'Disconnect',
            source: 'SmartAgent'
        }
    });
};

export const skipCall = () => {
    sendMsg({
        channel: 'Amazon',
        message: {
            channel: 'Amazon',
            topic: 'OutboundCall',
            subtopic: 'Skip',
            source: 'SmartAgent'
        }
    });
};

export const clearContact = () => {
    sendMsg({
        channel: 'Amazon',
        message: {
            channel: 'Amazon',
            topic: 'OutboundCall',
            subtopic: 'ClearContact',
            source: 'SmartAgent'
        }
    });
};

export const getCallOutcomes = (callId: string) => {
    sendMsg({
        channel: 'LCM',
        message: {
            channel: 'LCM',
            topic: 'WebService',
            subtopic: 'GetCallOutCome',
            source: 'SmartAgent',
            data: {
                callid: callId
            }
        }
    });
};

export const getParentBusinessOutcomes = (callId: string, accountNumber: string) => {
    sendMsg({
        channel: 'LCM',
        message: {
            channel: 'LCM',
            topic: 'WebService',
            subtopic: 'GetBOGroupParent',
            source: 'SmartAgent',
            data: {
                callid: callId,
                AccountNumber: accountNumber
            }
        }
    });
};

export const getChildBusinessOutcomes = (
    callId: string,
    accountNumber: string,
    parentBusinessOutcomeId: string
) => {
    sendMsg({
        channel: 'LCM',
        message: {
            channel: 'LCM',
            topic: 'WebService',
            subtopic: 'GetBusinessOutcomeParent',
            source: 'SmartAgent',
            data: {
                callid: callId,
                AccountNumber: accountNumber,
                ParentBusinessOutcomeId: parentBusinessOutcomeId
            }
        }
    });
};

export const getCampaignInfo = (callId: string, accountNumber: string) => {
    sendMsg({
        channel: 'LCM',
        message: {
            channel: 'LCM',
            topic: 'WebService',
            subtopic: 'GetModeInformation',
            source: 'SmartAgent',
            data: {
                callid: callId,
                AccountNumber: accountNumber
            }
        }
    });
};

export const sendBusinessOutcome = (outcome: BusinessOutcomeRequest) => {
    sendMsg({
        channel: 'LCM',
        message: {
            channel: 'LCM',
            topic: 'WebService',
            subtopic: 'SetBusinessOutcomeWithComments',
            source: 'SmartAgent',
            data: outcome
        }
    });
};
