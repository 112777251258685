import axios from 'axios';
import { BASE_API_URL } from './utils';

export enum LogLevel {
    ERROR = 'ERROR',
    DEBUG = 'DEBUG',
    INFO = 'INFO'
}

interface Log {
    contactID: string;
    logLevel: LogLevel;
    message: string;
    timestamp?: number;
}

const LOG_INTERVAL_MS = 10000;

export class LogHandler {
    private logs: Log[] = [];
    private saToken = 'dev';

    constructor() {
        setInterval(() => {
            this.sendLogs();
        }, LOG_INTERVAL_MS);
    }

    setSaToken(token: string) {
        this.saToken = token;
    }

    addLog(newLog: Log) {
        this.logs.push({
            ...newLog,
            timestamp: Date.now()
        });
    }

    private sendLogs() {
        if (this.logs.length === 0) return;
        axios
            .post(
                `${BASE_API_URL}/logs`,
                { logs: this.logs },
                {
                    headers: { 'X-Amz-Security-Token': this.saToken }
                }
            )
            .catch((err) => console.error('Failed to send logs ::', err));
        this.clearLogs();
    }

    private clearLogs() {
        this.logs = [];
    }
}
