/* eslint-disable @typescript-eslint/no-empty-function */
import { Button, Dropdown, Input } from '@missionlabs/smartagent-app-components';
import { Dispatch, SetStateAction, useEffect, useState } from 'react';
import {
    BusinessOutcomeRequest as BusinessOutcomeData,
    clearContact,
    getCallOutcomes,
    getChildBusinessOutcomes,
    getParentBusinessOutcomes,
    sendBusinessOutcome
} from '../../messaging';
import { CurrentCallInformation } from '../../types';
import './outcome.scss';

export interface Props {
    currentCallState: CurrentCallInformation;
    setCurrentCallState: Dispatch<SetStateAction<CurrentCallInformation>>;
}

interface Outcome {
    callOutcomeId: string;
    parentBusinessOutcomeId: string;
    childBusinessOutcomeId: string;
    target: string;
    comment: string;
    leadScore: string;
}

const Outcome = ({ currentCallState, setCurrentCallState }: Props) => {
    const {
        callId,
        accountNumber,
        callOutcomes,
        parentBusinessOutcomes,
        childBusinessOutcomes,
        agentUserId,
        callStatus
    } = currentCallState;
    const [outcome, setOutcome] = useState<Partial<Outcome>>({});

    useEffect(() => {
        if (callId) {
            getCallOutcomes(callId);

            if (accountNumber) {
                getParentBusinessOutcomes(callId, accountNumber);
            }
        }
    }, [callId]);

    useEffect(() => {
        if (outcome.parentBusinessOutcomeId && callId && accountNumber) {
            getChildBusinessOutcomes(callId, accountNumber, outcome.parentBusinessOutcomeId);
        }
    }, [outcome.parentBusinessOutcomeId]);

    const isOutcomeCommentValid = () => {
        if (outcome?.comment && outcome.comment.length > 1000) {
            return false;
        }
        return true;
    };

    const isSubmitDisabled =
        callStatus !== 'ACW' ||
        !isOutcomeCommentValid() ||
        !outcome.callOutcomeId ||
        !outcome.parentBusinessOutcomeId ||
        !outcome.childBusinessOutcomeId ||
        !outcome.target ||
        !outcome.comment ||
        !outcome.leadScore;

    const handleSubmit = () => {
        const {
            callOutcomeId,
            parentBusinessOutcomeId,
            childBusinessOutcomeId,
            target,
            comment,
            leadScore
        } = outcome;
        if (
            callId &&
            accountNumber &&
            callOutcomeId &&
            parentBusinessOutcomeId &&
            childBusinessOutcomeId &&
            target &&
            comment &&
            leadScore &&
            agentUserId
        ) {
            const businessOutcome: BusinessOutcomeData = {
                callid: callId,
                AccountNumber: accountNumber,
                OutcomeId: Number(callOutcomeId),
                TargetAmount: Number(target),
                UserId: agentUserId,
                AgentComments: comment,
                LeadScore: Number(leadScore)
            };

            sendBusinessOutcome(businessOutcome);
            clearContact();
        } else {
            console.error('Missing required parameters to submit outcome ::', outcome, agentUserId);
        }
    };

    return (
        <div className="outcome">
            <div className="outcome__dropdowns">
                <Dropdown
                    label="Call outcome"
                    placeholder="Select an option"
                    value={callOutcomes?.find((o) => outcome.callOutcomeId === o.id)?.label}
                    options={
                        callOutcomes?.map((outcome) => ({
                            label: outcome.label,
                            data: outcome.id
                        })) || []
                    }
                    required
                    onChange={(selected) => {
                        setOutcome((prevOutcome) => ({
                            ...prevOutcome,
                            callOutcomeId: selected.data
                        }));
                        setCurrentCallState((prevCallState) => ({
                            ...prevCallState,
                            callOutcome: selected.label
                        }));
                    }
                    }
                />
                <Dropdown
                    label="Parent business outcome"
                    value={
                        parentBusinessOutcomes?.find(
                            (o) => outcome.parentBusinessOutcomeId === o.id
                        )?.label
                    }
                    options={
                        parentBusinessOutcomes?.map((outcome) => ({
                            label: outcome.label,
                            data: outcome.id
                        })) || []
                    }
                    required
                    placeholder="Select an option"
                    onChange={(selected) => {
                        setOutcome((prevOutcome) => ({
                            ...prevOutcome,
                            parentBusinessOutcomeId: selected.data
                        }));
                    }}
                />
                <Dropdown
                    label="Child business outcome"
                    value={
                        childBusinessOutcomes?.find((o) => outcome.childBusinessOutcomeId === o.id)
                            ?.label
                    }
                    options={
                        childBusinessOutcomes?.map((outcome) => ({
                            label: outcome.label,
                            data: outcome.id
                        })) || []
                    }
                    placeholder="Select an option"
                    required
                    onChange={(selected) =>
                        setOutcome((prevOutcome) => ({
                            ...prevOutcome,
                            childBusinessOutcomeId: selected.data,
                            leadScore: childBusinessOutcomes?.find((o) => selected.data === o.id)
                                ?.leadScore
                        }))
                    }
                />
            </div>
            <div className="outcome__inputs">
                <Input
                    className="outcome__inputs-target"
                    label="Target"
                    type="number"
                    placeholder="Enter a target"
                    value={outcome.target || ''}
                    onChange={(e) =>
                        setOutcome((prevOutcome) => ({ ...prevOutcome, target: e as string }))
                    }
                    required
                />
                <Input
                    className="outcome__inputs-comment"
                    label="Comment"
                    type="text"
                    textarea
                    placeholder="Enter a comment"
                    value={outcome.comment || ''}
                    showError
                    onValidate={(val) =>
                        val.length > 1000 ? 'Comment must be 1000 characters or less' : ''
                    }
                    required
                    onChange={(e) =>
                        setOutcome((prevOutcome) => ({ ...prevOutcome, comment: e as string }))
                    }
                />
            </div>
            <Button
                className="outcome-button"
                styling="primary"
                onClick={handleSubmit}
                disabled={isSubmitDisabled}
            >
                Submit
            </Button>
        </div>
    );
};

export default Outcome;
