import React, { useEffect, useRef, useState } from 'react';
import SmartAgent from '@missionlabs/smartagent-app-components/dist/smartagent';
import { Box } from '@missionlabs/smartagent-app-components';
import '../../node_modules/@missionlabs/smartagent-app-components/dist/index.css';
import { ACQUEON_IFRAME_URL, BASE_API_URL } from '../utils';
import CallOverview from './CallOverview';
import ViewSwitcher from './ViewSwitcher';
import CustomerDetails from './CustomerDetails';
import CampaignInfo from './CampaignInfo';
import Outcome from './Outcome';
import { CurrentCallInformation } from '../types';
import { EventHandler } from '../event-handler';
import { getCampaignInfo } from '../messaging';
import  axios  from 'axios'

interface Props {
    sm?: SmartAgent;
}

const views = ['Customer details', 'Campaign info', 'Outcome'];

const Main: React.FC<Props> = (props) => {
    const sm = useRef<SmartAgent>(props.sm || new SmartAgent());
    const [selectedView, setSelectedView] = useState<string>(views[0]);
    const [token, setToken] = useState('');
    const [currentCallState, setCurrentCallState] = useState<CurrentCallInformation>({});
    const [statusError, setStatusError] = useState<boolean>(false)
    const eventHandler = new EventHandler(setCurrentCallState);

    useEffect(() => {
        setup();
        // eslint-disable-next-line
    }, []);

    const setup = async () => {
        const contact = await sm.current.init();
        eventHandler.setSaContactId(contact?.ID);
        refreshSaToken();

        sm.current.on('next_status', async (status: string) => {
            try {
                const token = (await sm.current.getToken()) || 'dev';
                const agentId = contact.attributes?.AgentName;
                if (!agentId) return;
                await axios.post(
                    `${BASE_API_URL}/agent/${agentId}/status`,
                    { agentState: status },
                    { headers: { 'x-amz-security-token': `${token}` } }
                );
            } catch (e) {
                setStatusError(true);
                console.error(e);
            }
        });

        sm.current.on('contact_updated', async (contact: { ID: string }) => {
            if (contact.ID) {
                eventHandler.setSaContactId(contact.ID);
            }
        });

        sm.current.showPlugin();
        window.addEventListener(
            'message',
            async (e) => {
                eventHandler.receiveMessage(e);
            },
            false
        );
    };

    const refreshSaToken = async () => {
        const token = await sm.current.getToken();
        setToken(token);
        eventHandler.setSaToken(token);
    }

    useEffect(() => {
        if (currentCallState.contactNumber) {
            window.parent?.postMessage(
                {
                    accountNumber: currentCallState.contactNumber,
                    source: 'tw-acqueon-plugin',
                    event: 'acqueon_contact',
                    type: 'smartagent'
                },
                '*'
            );
            refreshSaToken();
        }
    }, [currentCallState.contactNumber]);

    useEffect(() => {
        if (currentCallState.accountNumber && currentCallState.callId) {
            getCampaignInfo(currentCallState.callId, currentCallState.accountNumber);
        }
    }, [currentCallState.callId, currentCallState.accountNumber]);

    const renderView = () => {
        switch (selectedView) {
            case 'Customer details':
                return <CustomerDetails contactData={currentCallState.businessFields || {}} />;
            case 'Campaign info':
                return <CampaignInfo campaignInfo={currentCallState.campaignInfo || {}} />;
            case 'Outcome':
                return (
                    <Outcome
                        currentCallState={currentCallState}
                        setCurrentCallState={setCurrentCallState}
                    />
                );
        }
    };

    const renderInner = () => {
        return (
            <div className="plugin" style={{ height: '100%' }}>
                <iframe id="acqueon-iframe" src={ACQUEON_IFRAME_URL} />

                <CallOverview currentCall={currentCallState} statusError={statusError} />
                <ViewSwitcher
                    views={views}
                    selectedView={selectedView}
                    setSelectedView={setSelectedView}
                    callStatus={currentCallState.callStatus}
                />
                <Box className="plugin__views" alt header={selectedView} collapse>
                    {renderView()}
                </Box>
            </div>
        );
    };

    const isEmbedded = window.parent !== window;
    return isEmbedded ? (
        renderInner()
    ) : (
        <Box alt header="Acqueon plugin" collapse>
            {renderInner()}
        </Box>
    );
};

export default Main;
